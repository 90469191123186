import { Component, OnInit } from '@angular/core';

import { navItems } from './_nav';
import { Spinkit } from 'ng-http-loader';
import { GlobalService } from 'src/app/shared/global.service'; 

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent implements OnInit {

  public navItems = navItems;

  public spinkit = Spinkit;

  userRole : any;

  isBloodStorage : any;

  childrenItem : any;

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor(private globalService: GlobalService) {}

  // Expose globalService through a getter method
  get globalServiceInstance(): GlobalService {
    return this.globalService;
  }

  ngOnInit(): void {
    this.userRole = localStorage.getItem('role');
    this.isBloodStorage = localStorage.getItem('bloodStorage');
    this.filterMenuItemsByPermission();
  }

  filterMenuItemsByPermission() {
    this.navItems = navItems.filter(item => {
      if(this.userRole == 'Super Admin'){
        return true; //show all
      }else if(this.userRole == 'Admin'){
        if(item.name == "Super Admin"){
          return false; 
        }else {
          return true;
        }
      }else if(this.userRole == 'Technician' || this.userRole == 'Doctor'){
        if(item.name == "Super Admin" || item.name == "Admin") {
          return false;
        }else {
          return true;
        }
      }else if(this.userRole == 'Operator'){
        if(item.name == "Super Admin" || item.name == "Admin" || item.name == "Letters" || item.name == "Bag Details"){
          return false;
        }else {
          return true;
        }
      }else {
        if(item.name == "Super Admin" || item.name == "Admin" || item.name == "Letters" || item.name == "Camp Details" || item.name == "Donor Details" || item.name == "Bag Details" || item.name == "Stock Details" || item.name == "Registers"){
          return false;
        }else {
          return true;
        }
      }      
    });
    
    return this.navItems;
  }  
}

/*if (item.children && item.children.length) {
        item.children = item.children.filter(child => {
            // Set the initial visibility of all children to false
            let showChild = true;
            // Define conditions for showing children based on user roles
            if (this.userRole === 'Super Admin') {
                showChild = true; // Super Admin sees all children
            } else {
                if(item.name == "Registers" && this.isBloodStorage) {
                  if(child.name == 'Donor Register'){
                    showChild = false;
                  }
                }
            }
            return showChild;
        });
      }*/