// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'dev',
  //baseUrl: 'http://localhost:9090' // local server
  //baseUrl: 'http://62.72.56.227:9090'
  baseUrl: 'https://vp.bloodsystem.in'
};