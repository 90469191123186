import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    iconComponent: { name: 'cil-speedometer' },
  },

  {
    name: 'Super Admin',
    url: '/super-admin',
    iconComponent: { name: 'cil-cog' },
    children: [
      {
        name: 'Customer',
        url: '/super-admin/customer'
      },

      {
        name: 'Branch',
        url: '/super-admin/branch'
      },
    ], 
  },

  {
    name: 'Admin',
    url: '/admin',
    iconComponent: { name: 'cil-group' },
    children: [
      {
        name: 'User',
        url: '/admin/user'
      },
      {
        name: 'SMS Templates',
        url: '/admin/sms-templates'
      },
    ]
  },

  {
    name: 'Letters',
    url: '/letter',
    iconComponent: { name: 'cil-notes' },
    children: [
      {
        name: 'Letters',
        url: '/letter/letter-details'
      },
    ]
  },

  {
    name: 'Camp Details',
    url: '/camp',
    iconComponent: { name: 'cil-note-add' },
    children: [
      {
        name: 'Camp List',
        url: '/camp/camp-info',
      },
    ]
  },

  {
    name: 'Donor Details',
    url: '/donor',
    iconComponent: { name: 'cil-user-plus' },
    children: [
      {
        name: 'In-House Donor List',
        url: '/donor/donor-info',
      },
      {
        name: 'Camp Donor List',
        url: '/donor/camp-donor-info',
      },
      /*{
        name: 'Notify Donor',
        url: '/donor/notify-donor'
      },*/
    ]
  },

  {
    name: 'Bag Details',
    url: '/bag',
    iconComponent: { name: 'cib-addthis' },
    children: [
      {
        name: 'Bag List',
        url: '/bag/bag-details',
      },
    ]
  },

  {
    name: 'Registers',
    url: '/registers',
    iconComponent: { name: 'cil-notes' },
    children: [
      {
        name: 'Stock Register',
        url: '/registers/stock-register',
      },
      {
        name: 'Cross Match Register',
        url: '/registers/cross-match-register',
      },
      {
        name: 'Issue Register',
        url: '/registers/issue-register',
      },
      {
        name: 'Master Register',
        url: '/registers/master-register',
      },
      {
        name: 'Discard Register',
        url: '/registers/discard-register',
      },
      {
        name: 'Seperation Register',
        url: '/registers/seperation-register',
      },
      {
        name: 'Donor Register',
        url: '/registers/donor-register',
      },
      {
        name: 'Blood Grouping Register',
        url: '/registers/blood-grouping-register',
      },
    ]
  },

  {
    name: 'Stock Details',
    url: '/stock',
    iconComponent: { name: 'cil-drop' },
    children: [
      {
        name: 'Components',
        url: '/stock/stock-details',
      },
    ]
  },

  {
    name: 'Bill Book',
    url: '/billing',
    iconComponent: { name: 'cil-book' },
    children: [
      {
        name: 'Issue Bills',
        url: '/billing/billing-details',
      },

      {
        name: 'Expenses',
        url: '/billing/expense-details',
      },
    ]
  },

  {
    name: 'Catalog',
    url: '/catalog',
    iconComponent: { name: 'cil-notes' },
    children: [
      {
        name: 'Hospitals',
        url: '/catalog/hospital-details',
      },

      {
        name: 'Other Banks',
        url: '/catalog/other-bank-details',
      },
    ]
  },

  /*{
    title: true,
    name: 'Theme'
  },
  {
    name: 'Colors',
    url: '/theme/colors',
    iconComponent: { name: 'cil-drop' }
  },
  {
    name: 'Typography',
    url: '/theme/typography',
    linkProps: { fragment: 'someAnchor' },
    iconComponent: { name: 'cil-pencil' }
  },
  
  {
    name: 'Components',
    title: true
  },
  {
    name: 'Base',
    url: '/base',
    iconComponent: { name: 'cil-puzzle' },
    children: [
      {
        name: 'Accordion',
        url: '/base/accordion'
      },
      {
        name: 'Breadcrumbs',
        url: '/base/breadcrumbs'
      },
      {
        name: 'Cards',
        url: '/base/cards'
      },
      {
        name: 'Carousel',
        url: '/base/carousel'
      },
      {
        name: 'Collapse',
        url: '/base/collapse'
      },
      {
        name: 'List Group',
        url: '/base/list-group'
      },
      {
        name: 'Navs & Tabs',
        url: '/base/navs'
      },
      {
        name: 'Pagination',
        url: '/base/pagination'
      },
      {
        name: 'Placeholder',
        url: '/base/placeholder'
      },
      {
        name: 'Popovers',
        url: '/base/popovers'
      },
      {
        name: 'Progress',
        url: '/base/progress'
      },
      {
        name: 'Spinners',
        url: '/base/spinners'
      },
      {
        name: 'Tables',
        url: '/base/tables'
      },
      {
        name: 'Tabs',
        url: '/base/tabs'
      },
      {
        name: 'Tooltips',
        url: '/base/tooltips'
      }
    ]
  },
  {
    name: 'Buttons',
    url: '/buttons',
    iconComponent: { name: 'cil-cursor' },
    children: [
      {
        name: 'Buttons',
        url: '/buttons/buttons'
      },
      {
        name: 'Button groups',
        url: '/buttons/button-groups'
      },
      {
        name: 'Dropdowns',
        url: '/buttons/dropdowns'
      },
    ]
  },
  {
    name: 'Forms',
    url: '/forms',
    iconComponent: { name: 'cil-notes' },
    children: [
      {
        name: 'Form Control',
        url: '/forms/form-control'
      },
      {
        name: 'Select',
        url: '/forms/select'
      },
      {
        name: 'Checks & Radios',
        url: '/forms/checks-radios'
      },
      {
        name: 'Range',
        url: '/forms/range'
      },
      {
        name: 'Input Group',
        url: '/forms/input-group'
      },
      {
        name: 'Floating Labels',
        url: '/forms/floating-labels'
      },
      {
        name: 'Layout',
        url: '/forms/layout'
      },
      {
        name: 'Validation',
        url: '/forms/validation'
      }
    ]
  },
  {
    name: 'Charts',
    url: '/charts',
    iconComponent: { name: 'cil-chart-pie' }
  },
  {
    name: 'Icons',
    iconComponent: { name: 'cil-star' },
    url: '/icons',
    children: [
      {
        name: 'CoreUI Free',
        url: '/icons/coreui-icons',
        badge: {
          color: 'success',
          text: 'FREE'
        }
      },
      {
        name: 'CoreUI Flags',
        url: '/icons/flags'
      },
      {
        name: 'CoreUI Brands',
        url: '/icons/brands'
      }
    ]
  },
  {
    name: 'Notifications',
    url: '/notifications',
    iconComponent: { name: 'cil-bell' },
    children: [
      {
        name: 'Alerts',
        url: '/notifications/alerts'
      },
      {
        name: 'Badges',
        url: '/notifications/badges'
      },
      {
        name: 'Modal',
        url: '/notifications/modal'
      },
      {
        name: 'Toast',
        url: '/notifications/toasts'
      }
    ]
  },
  {
    name: 'Widgets',
    url: '/widgets',
    iconComponent: { name: 'cil-calculator' },
    badge: {
      color: 'info',
      text: 'NEW'
    }
  },
  /*{
    title: true,
    name: 'Extras'
  },
  {
    name: 'Pages',
    url: '/login',
    iconComponent: { name: 'cil-star' },
    children: [
      {
        name: 'Login',
        url: '/login'
      },
      {
        name: 'Register',
        url: '/register'
      },
      {
        name: 'Error 404',
        url: '/404'
      },
      {
        name: 'Error 500',
        url: '/500'
      }
    ]
  },*/
];
